@font-face {
  font-family: "BebasKai";
  src: url("font/BebasKai.otf") format("opentype"),
         url("font/BebasKai.ttf") format("truetype");
 }
 @font-face {
  font-family: "NeutraTextBookItalic";
  src: url("font/NeutraTextBookItalic.otf") format("opentype")
 }
 @font-face {
  font-family: "NeutraTextBookItalicAlt";
  src: url("font/NeutraTextBookItalicAlt.otf") format("opentype")
 }
 h1, span, a{
  font-family: "BebasKai";
 }
 p {
  font-family: "NeutraTextBookItalic";
 }
/* html {cursor: url(font/YellowNormal.cur), auto !important;} End https://www.cursors-4u.com */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.navigation-sub {
display: flex;
background: white;
display: flex;
position: fixed;
max-height: 140px;
width: 100%;
z-index: 1001;

}
 .navigation-sub a{
  margin:  3em;
  text-decoration: unset;
  font-weight: bold;
  color:black;
  height: 22px;
  letter-spacing: 3px;
  font-size: 1.1rem;

}
.logo{
margin-right: 5em;/*  */
margin:  2em 3em;
width: 70px;
display: flex;
min-height: 70px;
cursor: pointer;
}
.shop-btn:hover{
  filter: drop-shadow(8px 8px 10px #ffed00) ; 
  transform: scale(1.2);
  /* filter: hue-rotate(10deg); */
}
.shop-btn{
  margin:  2.5em;
  position: absolute;
  right: 4em;
  width: 130px;
height: auto;
cursor: pointer;
}
.active-NavLink{
  background-color: #ffed00;
}
.container{
padding: 0 1em;
max-width: 50rem;
margin: 0 auto;
width: 100%;
}
.about-maintext{
font-family: "BebasKai" !important;
text-transform: uppercase;
font-weight: 500;
font-size: 1.5rem;
text-align: left;
margin-bottom: 3rem;

    line-height: 1.9rem;
}
.about-secondtext{
  text-align: left;
  line-height: 1.3rem;
}
.aboutme-img{
  width: 175px;
animation: popin 2s
}
.contactme-img{
  width: 300px;
  animation: popin 2s
}
@keyframes popin {
  0% {
opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.social-block{
display: flex;
flex-direction: column;
margin: 0 1em;
align-items: center;
width: 5rem;
}
.social-block span{

  font-size: 0.8rem;
  }
.social-block-row{
  display: flex;
  margin-bottom: 3rem;
}
.social-block-row a{
color: black;
text-decoration: none;
}
.social-block-email{
  display: flex;
flex-direction: column;
  margin:auto;
  align-items: center;
}
.social-main-row{
  padding-top: 13rem;
}
.link-email{
  width: 100%;
}

.subnav a{
text-decoration: none;
color: black;
letter-spacing: 3px;
}
.gallery-photo p {
  text-align: center;
  max-width: 35rem;
  margin: 2em auto;
overflow-wrap: break-word;
}

.seemore-btn{
  transform: scale(1.1);
background: #ffed00;
border:none;
border-radius: 5px;
font-family: "BebasKai";
margin-top: 2rem;
}


@media screen and (max-width: 990px)  {
  .video-landing{
    width: 300px;
  }
  .leniparle-gif{
    /* bottom: 0em;
    right: 3em; */
    width: 220px;
    position: relative;

  }
  .shop-btn{
    margin: 1.6em 1em;
  width: 110px;
    right: 0;
  
  }
  .logo{
    margin: 0 auto 2em  auto;
    }

  .gallery-photo{
    padding-top: 10em;
 }
  .about-imgdiv{
  padding-bottom: 2em;
  }
  
.about-container{
  padding: 10em 1em;
}
 .iframe-detail{
 width:100%;
 height: 215px;
  }
  .keep-scroll{
    text-align: center !important;
  }
  .detail-photo{
    padding-top: 3rem;
    margin: 0 1em;
  }
  .detail-photo img{
    max-width: 100%;
    max-height: 33vh;
  }
  .focus-photo{
    padding-top: 10rem !important;
    padding: 0 1em;
    height: 190vh  !important;
  }
  .focus-photo img{
    max-width: 100%;
    height: auto;
  }
  .subnav{
    width: 100%;
    padding: 0.7em 1em;
    text-align: center;
    flex-direction: row !important;
    position: fixed;
    top: 6rem;
    margin-bottom: 3rem;
    z-index: 100;
    background: white;
    border-bottom: 1px solid;
  }
  .subnav a{
  margin: 0 0.5em;
  }
  .navigation-responsive{
    display: none;
  }
  .header-burger{
    height: 6rem;
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    border-bottom: 1px solid black;
  }


}
/* @media screen and (min-width: 991px) and (max-width: 1200px) {
.gallery-photo{

  top: 40%;

}
} */
@media screen and (min-width: 991px)  {
  .video-landing{
    width: 600px;
  }
  .leniparle-gif{
   
    position: relative;
    width: 300px;
    /* position: absolute; */
    -webkit-animation: slide-in-blurred-right 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0.4s both;
    animation: slide-in-blurred-right 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0.4s both;
  }

/* ----------------------------------------------
 * Generated by Animista on 2021-3-11 16:1:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

.about-container{
  padding: 17em 1em;
}
  .about-imgdiv{
    width: 100%;
    position: absolute;
      top: 9em;
      right: 30em;
  }
  
  .iframe-detail{
    width:700px;
    height:450px;
  }
  .detail-photo img{
    /* max-width: 700px; */
    max-height: 60vh;
    }
  .gallery-photo{
    position: absolute;
    width: 88%;
    height: 100vh;
    top:18%;
    margin-left: 12%
  }
  .keep-scroll{
  display: none;
  }
  .subnav{
    width: fit-content;
    text-align: left;
    margin: 0 2em;
    position: fixed;
    top: 10rem;


    background: white;
  }
  .navigation-responsive-burger{
    display: none;
  }
}




.landing-page{
  z-index: 10000;
  background-color: white;
height: 100vh;

position: relative;

}
.center-landing{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}
.bouton-landing svg:hover{
 
color:black;

}

.bouton-landing{
 
    bottom: 0;
    position: absolute;
    display: flex;
    color: #ffed00;
    justify-content: center;
    width: 100%;
}
.btn-retour-detail{
  font-weight: bold;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  left: 1.7em;
}
.btn-retour-detail:hover{
color:#ffed00;
transform: scale(1.2);

}

.focus-photo{
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff;
}


.photo-picked {
  width: fit-content;
  height: auto;
  
}
.hover-img{
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000cb;
  color: white;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

}
.seemore-btn:hover{
transform: scale(1.2);
}
 .hover-title h4{
  font-family: "BebasKai";
 letter-spacing: 0.12em;
} 
.hover-title h1{
  /* font-size: 4rem; */
  line-height: 1em;
  letter-spacing: 0.08em;
 
} 


.subnav a:hover {
 transform: scale(1.1);
 font-weight: bold;
}
.navigation-sub a:hover{
  transform: scale(1.2);
  font-weight: bolder;
}
.media-detail{
  display: flex;
    flex-direction: column;
    max-width: 700px;
    align-content: center;
    margin: 0 auto;
    max-height: 100vh;
}

.space-media{
margin: 2em 0;
/* max-height: 100vh; */

justify-content: center;
}
.space-media-insta{
  margin: 2em auto;
}

.link-ext span{
  font-size:1.3em
}
.link-ext a{
  font-size:1.3em
}

.react-photo-gallery--gallery div{
  justify-content: center;
}


.scale-in-top {
	-webkit-animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-10 19:23:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
}


.titre-detail{
  font-size: 4.1rem;
  letter-spacing: 0.16rem;
  margin-top: 2rem;
  max-width: 50rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  line-height: 0.9em;
}
.description-detail{
  line-height: 1.2em;
}
.date-detail{
  letter-spacing: 0.16rem;
  font-family: "BebasKai";
}

.social-block:hover {
  color: #ffed00;
  transform: scale(1.3);
}
.social-block-email:hover {
  color: #ffed00;
  transform: scale(1.3);
}

.titre-detail-container{
  width: 100%;
  text-align: center;
}

.social-items{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}






/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #f5f5f5;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: black;
    text-decoration: none;
}
.bm-item:hover{

  color: black !important;
   
}


/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
